import { PeriodDefinition } from '@capturi/filters'
import { Role } from 'features/aiTopics'
import React from 'react'
import { useNavigate } from 'react-router'

import AnalysisContext from '../contexts/AnalysisContext'
import { usePeriodContext } from '../contexts/PeriodContext'
import { useRoleContext } from '../contexts/RoleContext'

type TopicParams = {
  sourceId?: string
  topicIndex?: number
  phraseIndex?: number
  trends?: boolean
  conversations?: boolean
  role?: Role
  period?: PeriodDefinition
  sortDesc?: boolean
  sortId?: string
}

type UseTrendsNavigation = (options?: TopicParams) => void

type TrendPath = (
  sourceId?: string,
  topicIndex?: number,
  trends?: boolean,
  conversations?: boolean,
) => string

function useTrendsNavigation(trendPath: TrendPath): UseTrendsNavigation {
  const navigate = useNavigate()
  const { sourceId: sourceIdFromContext } = React.useContext(AnalysisContext)
  const [contextRole] = useRoleContext()
  const { periodDef: contextPeriod } = usePeriodContext()
  return React.useCallback(
    ({
      sourceId = sourceIdFromContext,
      topicIndex,
      phraseIndex,
      trends,
      conversations,
      role = contextRole,
      period = contextPeriod,
      sortId,
      sortDesc,
    } = {}) => {
      const path = trendPath(sourceId, topicIndex, trends, conversations)

      navigate({
        pathname: path,
        search: new URLSearchParams({
          ...(phraseIndex != null ? { phraseIndex: String(phraseIndex) } : {}),
          role,
          period: period.name,
          ...(sortId
            ? {
                sortId,
                sortDesc: String(sortDesc),
              }
            : {}),
        }).toString(),
      })
    },
    [navigate, contextRole, contextPeriod, sourceIdFromContext, trendPath],
  )
}

export default useTrendsNavigation
